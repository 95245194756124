import React from 'react'
import './Gallary.css'
import kulhad from '../assets/img/home1.jpg'
import sandwich from '../assets/img/bg15.jpg'
import pasta from '../assets/img/bg13.jpg'
import burger from '../assets/img/bg16.jpg'
import icecreme from '../assets/img/bg13.jpeg'
import coffee from '../assets/img/about5.jpg'

const Gallary = () => {
  return (
    <>
      <div className="container">
        <div className='row'>
          <div className="col-12 text-center">
            <h1 className='title fw-bold'>Gallary</h1>
            <p className=''>The Chai Chaska Bar and Cafe offers a delightful menu that caters to tea enthusiasts and food lovers alike. At the heart of the menu is a diverse selection of chai, from traditional masala chai to unique flavors like rose and saffron. Complementing these aromatic teas are an array of delicious snacks, including crispy pakoras, spicy samosas, and freshly baked naan stuffed with savory fillings. The cafe also features hearty options such as paneer tikka sandwiches and flavorful biryanis. For those with a sweet tooth, the menu boasts delectable desserts like gulab jamun and creamy kulfi. Each item is crafted to provide an authentic taste of India in a cozy, vibrant setting.</p>
          </div>
        </div>
      </div>
      <div class="gallery-image container">
        <div class="img-box ">
          <img src={kulhad} alt="" />
          <div class="transparent-box">
            <div class="caption">
              <p>Chai</p>
              <p class="opacity-low">Kullad Chai</p>
            </div>
          </div>
        </div>
        <div class="img-box">
          <img src={sandwich} alt="" />
          <div class="transparent-box">
            <div class="caption">
              <p>Sandwich</p>
              <p class="opacity-low">Special Grilled Sandwich</p>
            </div>
          </div>
        </div>
        <div class="img-box">
          <img src={pasta} alt="" />
          <div class="transparent-box">
            <div class="caption">
              <p>Pasta</p>
              <p class="opacity-low">Cheese Pasta</p>
            </div>
          </div>
        </div>
        <div class="img-box">
          <img src={burger} alt="" />
          <div class="transparent-box">
            <div class="caption">
              <p>Burger</p>
              <p class="opacity-low">Jumbo Burger</p>
            </div>
          </div>
        </div>
        <div class="img-box">
          <img src={icecreme} alt="" />
          <div class="transparent-box">
            <div class="caption">
              <p>Icecreame</p>
              <p class="opacity-low">Chocolate Layer Icecreame</p>
            </div>
          </div>
        </div>
        <div class="img-box">
          <img src={coffee} alt="" />
          <div class="transparent-box">
            <div class="caption">
              <p>Coffee</p>
              <p class="opacity-low">Strong Hot Coffee</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Gallary
