import React from 'react'

const Welcome = () => {
    return (
        <>
            <div className="row text-center">
                <div className="col-12">
                    <h1 className='fw-bold'>Welcome At</h1>
                    <h1 className='fw-bold welcomehead'>The Chai Chaska Bar and Cafe</h1>
                </div>
                <div className="col-12 pt-5">
                    Back in 2020, Sanket discovered India's most Loved, most Admired & most Popular Chai chain brand of India that would soar high across the globe. Thus, TCCB was born bringing on the table an interesting fusion of Western Vibes blended with the Royal Indian Culture of serving Chai to its customer cum family. The team at TCCB has been striving hard to take the brand across oceans with their spirits & head held as high as could be.
                </div>
                <div className="col-12 pt-5">
                    <button className='btn btn-danger px-3 py-2'>About Us</button>
                </div>
            </div>
        </>
    )
}

export default Welcome
