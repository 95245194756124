import React from 'react'
import './Home.css'
import logo from './assets/img/logo.png'
import Welcome1 from './assets/img/kulhad.jpg'
import welcome2 from './assets/img/bg13.jpg'
import welcome3 from './assets/img/home3.jpg'
import welcome4 from './assets/img/kulhad2.jpg'
import Mocups from './Mocups/Mocups'
import './Mocups/Mocups.css';
import './Welcome/Welcome.css'
import Welcome from './Welcome/Welcome'
import Menu from './Menu/Menu'
import './Menu/Menu.css'
import Gallary from './Gallary/Gallary'
import Map from './Map'


const Home = () => {
    return (
        <>
            <div className='container-fluid head'>
                <div className="container py-lg-5">
                    <div className="row">
                        <div className="col-12 d-grid justify-content-center text-center py-5">
                            <img src={logo} className="img-fluid headimg" width="150" alt="logo" />
                            <h1 className='text-light fw-bold headt'>The Chai Chaska Bar</h1>
                            <h2 className='text-light fw-bold headt'>Catch The Kulhad</h2>
                            <p className='headp fw-bold'>AT VERY RELAXING PLACE IN THE HEART OF THE CITY</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container moccontainer">
                <Mocups />
            </div>

            <div className="container welcontainer  py-5">
                <Welcome/>
            </div>

            <div className='container py-5'>
                <div className="row">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="row">
                            <div className="col-6 imagecontainer1">
                                <img src={Welcome1} className="img-fluid imagecon1" width="100%" height="100%" alt="logo" />
                                <img src={welcome2} className="img-fluid imagecon1" width="90%" height="100%" alt="logo" />
                            </div>
                            <div className="col-6 imagecontainer2">
                                <img src={welcome3} className="img-fluid imagecon2" width="90%" height="100%" alt="logo" />
                                <img src={welcome4} className="img-fluid imagecon2" width="100%" height="100%" alt="logo" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <h2 className='fw-bold'>"An Aromatic Journey Through India's Tea Culture at The Chai Chaska Bar Cafe."</h2>
                            </div>
                            <div className="col-12">
                                <p className='textpara'>⭐⭐⭐⭐⭐ The Chai Chaska Bar Cafe isn't just another cafe, it's an experience. From the moment you walk through its doors, you are enveloped in an atmosphere that's both warm and invigorating. The aromatic scent of freshly brewed chai tantalizes the senses, promising a cup that's both heartwarming and energizing.</p>
                                <p className='textpara'>Every sip of their signature chai transports you through the myriad tapestries of India's tea culture. It's evident that the meticulousness and passion poured into every cup is a homage to the rich history and traditions of chai. The cafe doesn't just stop at the drink though; their menu boasts a delightful array of snacks and dishes that perfectly complement their beverages. Each item is a symphony of flavors, carefully curated to tantalize the palate.</p>
                                <p className='textpara'>In a world of commercialized coffee shops and impersonal chains, The Chai Chaska Bar Cafe stands out like a beacon. It embodies the essence of what a cafe should be — a place not just to dine, but to unwind, reconnect, and savor the simpler pleasures of life. A must-visit gem for tea enthusiasts and anyone seeking a slice of serenity amidst the hustle and bustle.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className="container py-5">
                <Menu/>
                </div>
            </div>

            <div className='container-fluid'>
                <div className="container py-5">
                    <Gallary/>
                </div>
            </div>
            <div className=''>
                <Map/>
            </div>

        </>
    )
}

export default Home
