import React from 'react'
import './Admindashboard.css'
import image1 from '../../assets/img/menucard.jpg'
import image2 from '../../assets/img/bg15.jpg'
import image3 from '../../assets/img/home21.jpg'
const Admindashboard = () => {
  return (
    <>
      <div className='container-fluid dashhead'>
        <div className='container py-5'>
          <div className="row py-3">
            <div className="col-6">
              <button className='btn menubar' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i className="fa-solid fa-bars"></i></button>
            </div>
            <div className="col-6 d-flex justify-content-end logdiv">
              <span className='signin'><i className="fa-solid fa-user"></i> Sign in</span>
              <span className='signin'><i className="fa-solid fa-right-to-bracket"></i> Login</span>
              <span className='signin'><i className="fa-solid fa-gear"></i></span>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
            <div className="col">
              <div className="card dashcard h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8">
                      <p className='text-muted'>TODAY'S MONEY</p>
                      <h3 className='fw-bold'>₹53,000</h3>
                      <p><b className='percolor'>+55%</b> since yesterday</p>
                    </div>
                    <div className="col-4 text-center d-flex align-items-center">
                      <p className='dashlogo1'><i className="fa-solid fa-coins"></i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card dashcard h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8">
                      <p className='text-muted'>TODAY'S USERS</p>
                      <h3 className='fw-bold'>2,300</h3>
                      <p><b className='percolor'>+3%</b> since last week</p>
                    </div>
                    <div className="col-4 text-center d-flex align-items-center">
                      <p className='dashlogo2'><i className="fa-solid fa-users"></i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card dashcard h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8">
                      <p className='text-muted'>NEW CLIENTS</p>
                      <h3 className='fw-bold'>+3,462</h3>
                      <p><b className='percolor'>-2%</b> since last quarter</p>
                    </div>
                    <div className="col-4 text-center d-flex align-items-center">
                      <p className='dashlogo3'><i className="fa-solid fa-person-circle-plus"></i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card dashcard h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8">
                      <p className='text-muted'>SALES</p>
                      <h3 className='fw-bold'>$103,430</h3>
                      <p><b className='percolor'>+5%</b> than last month</p>
                    </div>
                    <div className="col-4 text-center d-flex align-items-center">
                      <p className='dashlogo4'><i className="fa-solid fa-file-waveform"></i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid carouseldiv'>
        <div className="container pb-5 d-flex justify-content-center">
            <div className="col-2 carbtn">
              <button className="carousel-prev text-dark" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <i class="fa-solid fa-circle-arrow-left"></i>
              </button>
            </div>
            <div className='col-8'>
              <div id="carouselExampleFade" className="carousel slide carousel-fade">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={image1} className="d-block w-100 carouselimg" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={image2} className="d-block w-100 carouselimg" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={image3} className="d-block w-100 carouselimg" alt="..." />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-2 carbtn'>
              <button className="carousel-next text-dark" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <i class="fa-solid fa-circle-arrow-right"></i>
              </button>
            </div>
          </div>
      </div>

      <div className='container-fluid'>
        <div className="container py-5">
          <div className="row tablesrow">
            <div className="col-7 reporttable px-4">
              <h3 className='fw-bold py-3'>Todays Sales Report</h3>
              <table className='table table-bodered'>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>kulhad Pizza</td>
                    <td>Pizza</td>
                    <td>15</td>
                    <td>₹100</td>
                    <td>1500</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>kulhad Pizza</td>
                    <td>Pizza</td>
                    <td>15</td>
                    <td>₹100</td>
                    <td>1500</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>kulhad Pizza</td>
                    <td>Pizza</td>
                    <td>15</td>
                    <td>₹100</td>
                    <td>1500</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>kulhad Pizza</td>
                    <td>Pizza</td>
                    <td>15</td>
                    <td>₹100</td>
                    <td>1500</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-4 cattable px-4">
              <h3 className='fw-bold py-3'>Menu Categories</h3>
              <div className="d-flex">
                <div className="col-3 d-flex text-center align-items-center">
                  <button className='btn btn-dark caticon'><i class="fa-solid fa-pizza-slice"></i></button>
                </div>
                <div className="col-7 catinfodiv">
                  <span>Pizza</span>
                  <span>250 in stock, 346+ sold</span>
                </div>
                <div className="col-2 d-flex text-center align-items-center">
                  <p><i class="fa-solid fa-chevron-right"></i></p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 d-flex text-center align-items-center">
                  <button className='btn btn-dark caticon'><i class="fa-solid fa-pizza-slice"></i></button>
                </div>
                <div className="col-7 catinfodiv">
                  <span>Pizza</span>
                  <span>250 in stock, 346+ sold</span>
                </div>
                <div className="col-2 d-flex text-center align-items-center">
                  <p><i class="fa-solid fa-chevron-right"></i></p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 d-flex text-center align-items-center">
                  <button className='btn btn-dark caticon'><i class="fa-solid fa-pizza-slice"></i></button>
                </div>
                <div className="col-7 catinfodiv">
                  <span>Pizza</span>
                  <span>250 in stock, 346+ sold</span>
                </div>
                <div className="col-2 d-flex text-center align-items-center">
                  <p><i class="fa-solid fa-chevron-right"></i></p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 d-flex text-center align-items-center">
                  <button className='btn btn-dark caticon'><i class="fa-solid fa-pizza-slice"></i></button>
                </div>
                <div className="col-7 catinfodiv">
                  <span>Pizza</span>
                  <span>250 in stock, 346+ sold</span>
                </div>
                <div className="col-2 d-flex text-center align-items-center">
                  <p><i class="fa-solid fa-chevron-right"></i></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Admindashboard
