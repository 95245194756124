import React from 'react'
// import '/Mocups.css'
import moc1 from '../assets/img/desktopmoc3.png'
import moc3 from '../assets/img/mobilemoc3.png'
import moc2 from '../assets/img/tabletmoc1.png'
const Mocups = () => {
  return (
    <>
        <img src={moc1} className="img-fluid moc1" alt="logo" />
        <img src={moc2} className="img-fluid moc2" alt="logo" />
        <img src={moc3} className="img-fluid moc3" alt="logo" />

    </>
  )
}

export default Mocups
