import React from 'react'
import './About.css'
import '../Map.js'
import owenerimg from '../assets/img/owener.jpg'
import Map from '../Map.js'

const About = () => {
  return (
    <>
    <div className="container-fluid abouthead">
        <div className="container py-5">
            <div className="row pt-5">
                <div className="col-12 d-flex justify-content-center">
                    <img className='img-fluid ownerimg' width="400" src={owenerimg} alt="" />
                </div>
                <div className="col-12 text-center text-light">
                    <h1 className='ownername fw-bold'>Sanket Bhimrao Jadhav</h1>
                    <h4 className='ownerdesignation fw-bold'>FOUNDR & OWNER OF</h4>
                    <h1 className='ownerbusiness fw-bold'>The Chai Chaska Bar And Cafe</h1>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid">
        <div className="container py-5">
            <div className="row">
                <div className="col-12 text-center pt-5">
                    <h1 className='fw-bold about'>About The Owner</h1>
                    <p className='text-muted fw-bold'>Chai has a strange influence to make you feel upscale and sophisticated. It is also the best drink to bond over, talk, and chit chat with friends. And it's no secret that Indians love their Chai (it's their most consumed drink if you don't count water), so it's only appropriate that they also have some of the best shops, where you can spend some amazing time with your friends or alone. If you are visiting Pune, then you should definitely spend some time at the amazing cafes there. Check out the list of The Chai Chaska Bar and Cafes.</p>
                </div>

                <div className="col-12 text-center pt-5">
                    <h1 className='fw-bold about pt-5'>About The Chai Chaska Bar Cafe</h1>
                    <p className='text-muted fw-bold'>Back in 2020, Sanket discovered India's most Loved, most Admired & most Popular Chai chain brand of India that would soar high across the globe. Thus, TCCB was born bringing on the table an interesting fusion of Western Vibes blended with the Royal Indian Culture of serving Chai to its customer cum family. The team at TCCB has been striving hard to take the brand across oceans with their spirits & head held as high as could be.</p>
                </div>

                <div className="col-12 text-center pt-5">
                    <h1 className='fw-bold about pt-5'>Join Us</h1>
                    <p className='text-muted fw-bold'>"Embark on a flavorful journey with The Chai Chaska Bar Cafe. Expand your horizons and be part of our growing legacy. Franchise opportunities are now available. Bring our cherished tea experience to your community. For partnerships and details, reach out to us. Contact today and brew success together!"</p>
                    <button type='button' className='btn btn-outline-danger'>Contact Us</button>
                </div>
            </div>
        </div>
    </div>
    <Map/>
    </>
  )
}

export default About
