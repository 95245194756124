import React from 'react'
import kulhadpizza from '../assets/img/kulhadpizza.jpg'
import crustpizza from '../assets/img/crustpizza.jpeg'
import cheesepizza from '../assets/img/cheesepizza.jpeg'
import vegcheesepizza from '../assets/img/vegcheesepizza.jpeg'
import americarcornpizza from '../assets/img/americanpizza.jpeg'
import chillicheesepizza from '../assets/img/chillicheesepizza.jpeg'
import pannercheesepizz from '../assets/img/pannercheesepizza.jpeg'
import olivemixpizza from '../assets/img/olivemixpizza.jpeg'
import tccbsplpizza from '../assets/img/tccbsplpizza.jpg'
import vegburger from '../assets/img/vegburger.jpeg'
import cheesevegburger from '../assets/img/cheesevegburger.jpeg'
import burgerpng from '../assets/img/burgerpng.png'
import fries from '../assets/img/fries.png'
import fries1 from '../assets/img/fries.avif'
import fries2 from '../assets/img/fries2.webp'
import fries3 from '../assets/img/friesimg.png'
import pasta from '../assets/img/pastaimg.png'
import maggie from '../assets/img/maggie.png'
import chocolatesandwich from '../assets/img/sandwich6.png'
import cheesesandwich from '../assets/img/sandwich2png.png'
import vegmayosandwich from '../assets/img/sandwich3.webp'
import cornmayosandwich from '../assets/img/sandwich2.png'
import grilledvegsandwich from '../assets/img/sandwich5.png'
import chillichatpatasandwich from '../assets/img/sandwichpng.png'
import tandoorisandwich from '../assets/img/sandwich4.png'
import paneersandwich from '../assets/img/sandwich.png'
import vegcheesesandwich from '../assets/img/sandwich.webp'
import flaverchai from '../assets/img/drink1.webp'
import hotcoffee from '../assets/img/drink2.png'
import coldcoffee from '../assets/img/drink3.jpg'
import kuka from '../assets/img/drink4.webp'
import mocktails from '../assets/img/drink5.png'
import shakes from '../assets/img/drink6.webp'
import icetea from '../assets/img/drink7.webp'
import fruitbeer from '../assets/img/drink8.png'
import fruitshake from '../assets/img/drink9.png'


const Menu = () => {
    return (
        <>
            <div className="row">
                <div className="col-12 text-center pb-4">
                    <h1 className='title fw-bold'>Our Special Menu</h1>
                </div>
            </div>
            <ul className="nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="btn active btn-custom fw-bold" id="pizza-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><i className="menuicons fa-solid fa-pizza-slice"></i>Pizza</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="btn btn-custom fw-bold" id="burger-tab" data-bs-toggle="tab" data-bs-target="#burger" type="button" role="tab" aria-controls="burger" aria-selected="false"><i className="menuicons fa-solid fa-burger"></i>Burgers</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="btn btn-custom fw-bold" id="snacks-and-sides-tab" data-bs-toggle="tab" data-bs-target="#snacks" type="button" role="tab" aria-controls="snacks" aria-selected="false"><i className="menuicons fa-solid fa-cookie-bite"></i>Snacks & Sides</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="btn btn-custom fw-bold" id="sandwich-tab" data-bs-toggle="tab" data-bs-target="#sandwich" type="button" role="tab" aria-controls="sandwich" aria-selected="false"><i className="menuicons fa-solid fa-hotdog"></i>Sandwich</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="btn btn-custom fw-bold" id="drinks-tab" data-bs-toggle="tab" data-bs-target="#drinks" type="button" role="tab" aria-controls="drinks" aria-selected="false"><i className="menuicons fa-solid fa-martini-glass-citrus"></i>Drinks</button>
                </li>
            </ul>
            <div className="tab-content py-5" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="pizza-tab">
                    <div className="row">
                        <div className="col-12 menudiv">
                            <div className='hrdiv1'></div>
                            <p className='menuname fw-bold'><i className="fa-solid fa-pizza-slice"></i>&nbsp;<span>Pizza</span></p>
                            <div className='hrdiv2'></div>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <p className='text-muted'>32cm / 48cm</p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row ">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={kulhadpizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>kulhad Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹100</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={crustpizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Crust Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹100</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={cheesepizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Cheese Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹110</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={vegcheesepizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Veg Cheese Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹130</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={americarcornpizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>American Corn Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹140</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={chillicheesepizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Chili Cheese Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹140</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={pannercheesepizz} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Panner Cheese Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹160</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={olivemixpizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Olive Mix Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹150</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={tccbsplpizza} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>TCCB Spl. Pizza</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹190</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="burger" role="tabpanel" aria-labelledby="burger-tab">
                    <div className="row">
                        <div className="col-12 menudiv">
                            <div className='hrdiv1'></div>
                            <p className='menuname fw-bold'><i className="fa-solid fa-burger"></i>&nbsp;<span>Burgers</span></p>
                            <div className='hrdiv2'></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row ">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={vegburger} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Veg Burger</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹70</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={cheesevegburger} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Cheese Veg Burger</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹80</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={burgerpng} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Paneer Tikka Burger</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹120</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={burgerpng} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Maharaja Burger</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹140</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="snacks" role="tabpanel" aria-labelledby="snacks-and-sides-tab">
                    <div className="row">
                        <div className="col-12 menudiv">
                            <div className='hrdiv1'></div>
                            <p className='menuname fw-bold'><i className="fa-solid fa-cookie-bite"></i>&nbsp;<span>Snacks & Sides</span></p>
                            <div className='hrdiv2'></div>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <p className='text-muted'>Small / Large</p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row ">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={fries} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>French Fries</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹80</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={fries1} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Peri Peri Fries</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹90</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={fries2} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Cheese Fries</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹110</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={fries3} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Masala Fries</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹90</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={fries} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Loaded Fries</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹120</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={pasta} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Pasta</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹90-110</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={maggie} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Maggie's</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹50-₹110</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="sandwich" role="tabpanel" aria-labelledby="sandwich-tab">
                    <div className="row">
                        <div className="col-12 menudiv">
                            <div className='hrdiv1'></div>
                            <p className='menuname fw-bold'><i className="fa-solid fa-hotdog"></i>&nbsp;<span>Sandwich</span></p>
                            <div className='hrdiv2'></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row ">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={chocolatesandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Chocolate Sandwich</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹70</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={cheesesandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Cheese Chutney</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹70</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={vegmayosandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Veg Mayo</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹80</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={cornmayosandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Corn Mayo</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹80</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={grilledvegsandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Grilled Veg</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹90</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={chillichatpatasandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Chilli Chat-Pata</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹80</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={tandoorisandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Tandoori Sandwich</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹90</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={paneersandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Paneer Taka Tak</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹100</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={vegcheesesandwich} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Veg Cheese Sandwich</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹110</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="drinks" role="tabpanel" aria-labelledby="drinks-tab">
                    <div className="row">
                        <div className="col-12 menudiv">
                            <div className='hrdiv1'></div>
                            <p className='menuname fw-bold'><i className="fa-solid fa-martini-glass-citrus"></i>&nbsp;<span>Drinks</span></p>
                            <div className='hrdiv2'></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row ">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={flaverchai} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Flavour Chai</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹12-₹50</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={hotcoffee} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Hot Coffee</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹20-₹60</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={coldcoffee} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Cold Coffee</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹50-₹100</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={kuka} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Kuka</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹40-₹800</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={mocktails} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Mocktails</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹60-₹80</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={shakes} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Shakes</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹90</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={icetea} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Ice Tea</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹40-₹70</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={fruitbeer} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Fruit Beer</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹80</p></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 menulist">
                            <div className="row">
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid menuimg'  src={fruitshake} alt="" />
                                </div>
                                <div className='col-6 d-flex align-items-center'><p className='menutitle'>Fruit Shake</p></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'><p>₹80</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Menu
