import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="text-center text-lg-start bg-dark text-light">
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    <div className="me-5 d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div>
                    <div>
                        <a href="/" className="me-4 text-reset">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="/" className="me-4 text-reset">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="/" className="me-4 text-reset">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </section>
                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <h1>Thank you for visiting us!</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-4 footerback" >
                    © 2024 Copyright:
                    <a className="text-reset fw-bold" href="https://jayvijaytech.com"> jayvijaytech.com</a>
                </div>
            </footer>
        </>
    )
}

export default Footer
