// import Adminnav from './Admin/Adminnav/Adminnav';
import './App.css';
import Footer from './Footer';
import Home from './Home';
import Navbar from './Navbar';
import './Footer.css'
import Menupage from './Menupage/Menupage';
import Gallarypage from './Gallarypage/Gallarypage';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import About from './About/About';
import Admindashboard from './Admin/AdminDashboard/Admindashboard';
import ScrollToTop from './ScrollToTop';

function App() {


  return (
    <>
    {/* <Adminnav/> */}
    
    <BrowserRouter>
    <ScrollToTop>
    <Navbar />
    <Routes>
    <Route path="/" element = {<Home/>} />
    <Route path="/about" element = {<About/>} />
    <Route path="/menu" element = {<Menupage/>} />
    <Route path="/gallary" element = {<Gallarypage/>} />
    <Route path="/" element = {<Admindashboard/>} />
    </Routes>
    <Footer />
    </ScrollToTop>
    </BrowserRouter>
    </>
  );
}

export default App;
