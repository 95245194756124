import React from 'react';

const Map = () => {
  return (
    <>
      <iframe
        title="Google Maps Embed For The Chai Chask Bar and Cafe"
        src="https://www.google.com/maps/d/u/0/embed?mid=1HPziHw_Vrp_CqPbCjjUMB1jCZcn8Bh8&ehbc=2E312F&noprof=1"
        width="100%"
        height="280"
      ></iframe>
    </>
  );
}

export default Map;
