import React from 'react'
import './Gallarypage.css'
import kulhad from '../assets/img/home1.jpg'
import sandwich from '../assets/img/bg15.jpg'
import pasta from '../assets/img/bg13.jpg'
import burger from '../assets/img/bg16.jpg'
import icecreme from '../assets/img/bg13.jpeg'
import coffee from '../assets/img/about5.jpg'
import gallary1 from '../assets/img/about.avif'
import gallary2 from '../assets/img/about2.jpg'
import gallary3 from '../assets/img/about4.jpg'
import gallary4 from '../assets/img/bg16.jpg'
import gallary5 from '../assets/img/bg18.jpg'
import gallary6 from '../assets/img/header2.jpg'
import gallary7 from '../assets/img/home21.jpg'
import gallary8 from '../assets/img/burger.webp'
import gallary9 from '../assets/img/kulhadmainpng.webp'
import gallary10 from '../assets/img/home1.jpg'
import gallary11 from '../assets/img/menu2.avif'
import gallary12 from '../assets/img/burger1.jpg'
import gallary13 from '../assets/img/menu3.png'
import gallary14 from '../assets/img/menu4.webp'
import gallary15 from '../assets/img/image1.jpg'
import gallary16 from '../assets/img/menu5.webp'
import gallary17 from '../assets/img/menu6.jpg'
import gallary18 from '../assets/img/menu1.webp'
const Gallarypage = () => {
    return (
        <>
            <div className="container-fluid py-5 gallarypagehead">
                <div className="container py-5 text-center">
                    <h1 className='fw-bold py-5 gallarypagetitle'>Photo Gallary</h1>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container py-5">
                    <div className='row'>
                        <div className="col-12 text-center">
                            <h1 className='title fw-bold titlegall'>Gallary</h1>
                            <p className=''>The Chai Chaska Bar and Cafe offers a delightful menu that caters to tea enthusiasts and food lovers alike. At the heart of the menu is a diverse selection of chai, from traditional masala chai to unique flavors like rose and saffron. Complementing these aromatic teas are an array of delicious snacks, including crispy pakoras, spicy samosas, and freshly baked naan stuffed with savory fillings. The cafe also features hearty options such as paneer tikka sandwiches and flavorful biryanis. For those with a sweet tooth, the menu boasts delectable desserts like gulab jamun and creamy kulfi. Each item is crafted to provide an authentic taste of India in a cozy, vibrant setting.</p>
                        </div>
                    </div>
                </div>
                <div class="gallery-image">
                    <div class="img-box">
                        <img src={kulhad} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Chai</p>
                                <p class="opacity-low">Kullad Chai</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={sandwich} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Sandwich</p>
                                <p class="opacity-low">Special Grilled Sandwich</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={pasta} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Pasta</p>
                                <p class="opacity-low">Cheese Pasta</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={burger} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Burger</p>
                                <p class="opacity-low">Jumbo Burger</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={icecreme} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Icecreame</p>
                                <p class="opacity-low">Chocolate Layer Icecreame</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={coffee} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Coffee</p>
                                <p class="opacity-low">Strong Hot Coffee</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary1} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Coffee</p>
                                <p class="opacity-low">Strong Hot Coffee</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary2} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Coffee</p>
                                <p class="opacity-low">Strong Hot Coffee</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary3} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Coffee</p>
                                <p class="opacity-low">Multi Type of Hot Coffes</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary4} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Burger</p>
                                <p class="opacity-low">Red Chilli Hot Burger</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary5} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Burger & Fries</p>
                                <p class="opacity-low">Burger & Fries Combo</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary6} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Kullad Chai</p>
                                <p class="opacity-low">Chocalate Flaver Kullad Chai</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary7} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Combo</p>
                                <p class="opacity-low">Kullad Kukka, Sandwich & Fries</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary8} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Burger</p>
                                <p class="opacity-low">Large Tikki Burger</p>
                            </div>
                        </div>
                    </div>

                    <div class="img-box">
                        <img src={gallary9} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Kullad</p>
                                <p class="opacity-low">Special Kullad Wali Chai</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary10} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Chai</p>
                                <p class="opacity-low">Kullad Masala Chai</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary11} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Pizza</p>
                                <p class="opacity-low">Onion Tomato Pizza</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary12} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Combo</p>
                                <p class="opacity-low">Burger Fries Combo</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary13} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Coffee</p>
                                <p class="opacity-low">Larger Icecreame Cold Coffee</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary14} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Coffee</p>
                                <p class="opacity-low">Hold Coffe With Milk</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary15} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Burger Combo</p>
                                <p class="opacity-low">Small Combo Pack</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary16} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Sandwich</p>
                                <p class="opacity-low">Special TCCB Grilled Sandwich</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary17} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Mocktails</p>
                                <p class="opacity-low">Multi Flaver Mocktails</p>
                            </div>
                        </div>
                    </div>
                    <div class="img-box">
                        <img src={gallary18} alt="" />
                        <div class="transparent-box">
                            <div class="caption">
                                <p>Burger</p>
                                <p class="opacity-low">extra Large Burger</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallarypage
