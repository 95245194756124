import React from 'react'
import './Menupage.css'
import menu1 from '../assets/img/menu1.webp'
import menu2 from '../assets/img/menu2.avif'
import menu3 from '../assets/img/menu3.png'
import menu4 from '../assets/img/menu4.webp'
import menu5 from '../assets/img/menu5.webp'
import menu6 from '../assets/img/menu6.jpg'
import Menu from '../Menu/Menu'
import '../Menu/Menu.css'

const Menupage = () => {
    return (
        <>
            <div className="container-fluid py-5 menupagehead">
                <div className="container py-5 text-center">
                    <h1 className='fw-bold py-5 menupagetitle'>Our Special Menu</h1>
                </div>
            </div>
            <div className="container-fluid pt-5">
                <div className="container py-5 px-lg-5">
                    <div className="row pb-5">
                        <div className="col-12 text-center fw-bold text-muted">
                            <p>The Chai Chaska Bar & Cafe offers a delightful variety of food items that cater to diverse tastes. Their menu features both classic and contemporary dishes, making it a perfect spot for food enthusiasts. Whether you crave a hearty meal or a light snack, you'll find something to satisfy your palate. The cafe's ambiance enhances the dining experience, providing a cozy setting for enjoying delicious food. Popular items include burgers, pizzas, and sandwiches, all prepared with fresh ingredients. Their beverage selection, including hot and cold coffees and refreshing mocktails, complements the food perfectly. Each item is crafted with care, ensuring quality and flavor in every bite.</p>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 grid-container">
                        <div className="col">
                            <div className="card h-100">
                                <img src={menu1} className="card-img-top" alt="..." />
                                <div className="card-body px-0">
                                    <span className="card-title">Burger</span>
                                    <h4 className='fw-bold py-2'>Jumbo Burger</h4>
                                    <p className="card-text">Juicy, flavorful burgers at The Chai Chaska Bar & Cafe are a must-try. They feature fresh ingredients and come with a variety of toppings. The patties are grilled to perfection, offering a satisfying bite. Served with crispy fries, they make for a complete meal. Enjoy them with your choice of drink.</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={menu2} className="card-img-top" alt="..." />
                                <div className="card-body px-0">
                                    <span className="card-title">Pizza</span>
                                    <h4 className='fw-bold py-2'>Onian & Tomato Pizza</h4>
                                    <p className="card-text">Pizzas here are a blend of classic and modern flavors. With a crispy crust and generous toppings, each slice is a delight. Options range from vegetarian to meat lovers' choices. The cheese is perfectly melted, adding richness. Ideal for sharing, pizzas are a crowd favorite.</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={menu3} className="card-img-top" alt="..." />
                                <div className="card-body px-0">
                                    <span className="card-title">Coffee</span>
                                    <h4 className='fw-bold py-2'>Cold Coffee</h4>
                                    <p className="card-text">Their cold coffee is a refreshing treat, perfect for hot days. It blends rich coffee with ice and milk for a smooth, creamy texture. Slightly sweetened, it’s both energizing and delicious. Served in a chilled glass, it’s a perfect pick-me-up. Ideal for coffee lovers seeking a cold alternative.</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={menu4} className="card-img-top" alt="..." />
                                <div className="card-body px-0">
                                    <span className="card-title">Coffee</span>
                                    <h4 className='fw-bold py-2'>Hot Coffee</h4>
                                    <p className="card-text">The hot coffee at The Chai Chaska Bar & Cafe is robust and aromatic. Made from high-quality beans, it offers a rich, full-bodied flavor. Each cup is brewed fresh, ensuring optimal taste. It’s perfect for starting your day or enjoying a cozy moment. Available in various styles to suit your preference.</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={menu5} className="card-img-top" alt="..." />
                                <div className="card-body px-0">
                                    <span className="card-title">Sadwich</span>
                                    <h4 className='fw-bold py-2'>Veg Grilled Sandwich</h4>
                                    <p className="card-text">Sandwiches here are made with fresh bread and flavorful fillings. Options include vegetarian and non-vegetarian choices. Each sandwich is crafted to balance taste and texture. Served with a side of chips or salad, they make a satisfying meal. Ideal for a quick, delicious bite.</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={menu6} className="card-img-top" alt="..." />
                                <div className="card-body px-0">
                                    <span className="card-title">Mocktails</span>
                                    <h4 className='fw-bold py-2'>Multi Flaver Mocktails</h4>
                                    <p className="card-text">The mocktails are a burst of refreshing flavors. Made with fresh fruits and juices, they are perfect for any time of day. Each drink is creatively mixed to provide a unique taste. They are non-alcoholic, making them suitable for all ages. Enjoy these vibrant beverages as a delightful accompaniment to your meal.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <Menu/>
                </div>
            </div>
        </>
    )
}

export default Menupage
