import React, { useEffect, useState } from 'react';
import logo from './assets/img/LOGO.jpg';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <nav className={`navbar ${scrolled ? 'bg-danger' : 'bg-transparent'} fixed-top text-light`}>
                <div className="container-fluid">
                    <div className="container main px-2">
                        <div className='logo'>
                            <Link className="navbar-brand text-light fw-bold" to="/">
                                <img src={logo} className="img-fluid" width="40" alt="logo" /> The Chai Chaska Bar Cafe
                            </Link>
                        </div>
                        <div className='text-light d-flex nav-items'>
                            <Link className="nav-link" aria-current="page" to="/">Home</Link>
                            <Link className="nav-link" to="/about">About</Link>
                            <Link className="nav-link" to="/menu">Menu</Link>
                            <Link className="nav-link" to="/gallary">Gallary</Link>
                            <Link className="nav-link" to="https://www.instagram.com/the.chai.chaska.bar_official/" target='_blank'><i className="fa-brands fa-instagram"></i></Link>
                            <Link className="nav-link" to="/" target='_blank'><i className="fa-brands fa-square-facebook"></i></Link>
                            <Link className="nav-link" to="/" target='_blank'><i className="fa-brands fa-x-twitter"></i></Link>
                        </div>
                        <div className='toggler'>
                            <button className="btn btn-outline-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars"></i>
                            </button>
                            <div className="offcanvas w-75 offcanvas-end text-bg-danger text-light" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                                        <Link className="navbar-brand fw-bold text-light" to="/">
                                            <img src={logo} className="img-fluid" width="40" alt="logo" /> TCCB
                                        </Link>
                                    </h5>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav justify-content-start flex-grow-1 pe-3">
                                        <li className="nav-item">
                                            <button className='btn' aria-label="Close" data-bs-dismiss="offcanvas"><Link className="nav-link fw-bold text-light active" to="/">Home</Link>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button className='btn' aria-label="Close" data-bs-dismiss="offcanvas"><Link className="nav-link fw-bold text-light" to="/about">About</Link></button>
                                        </li>
                                        <li className="nav-item">
                                            <button className='btn' aria-label="Close" data-bs-dismiss="offcanvas"><Link className="nav-link fw-bold text-light" to="/menu">Menu</Link></button>
                                        </li>
                                        <li className="nav-item">
                                        <button className='btn' aria-label="Close" data-bs-dismiss="offcanvas"><Link className="nav-link fw-bold text-light" to="/gallary">Gallary</Link></button>
                                        </li>
                                        <li className="nav-item">
                                        <button className='btn' aria-label="Close" data-bs-dismiss="offcanvas"><Link className="btn fw-bold text-light" to="https://www.instagram.com/the.chai.chaska.bar_official/" target='_blank'><i className="fa-brands fa-instagram"></i></Link></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
